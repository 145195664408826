import React, { useState, createContext, useContext, useEffect } from 'react';
// Libraries
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
// Context
import { BrowserContext } from './BrowserContext';
import { AuthContext } from './AuthContext';
import { ThemeContext } from './ThemeContext';
import { LanguageContext } from './LanguageContext';
// Environments
import Debug from '~/environments/Debug';
import UrlParameters from '~/environments/UrlParameters';
// Components
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
import Loading from '~/components/loading';
import LoadingGateway from '~/components/loading/loadingGateway';
// Utils
import { CookieNotification } from '~/utils/Notifications';

const isDebug = Debug.Context.Interface;

//! Procurar nos cookies, se tiver algum tema, seta a cor, senão coloca o light
//! Verificar se funciona mesmo não carregando a props.children

const Container = styled.div`
  background-color: ${(style) => style.backgroundColor || '#fff'};
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${(style) => style.primary || '#fff'};
`;

const ApologyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const InterfaceContext = createContext();

const InterfaceContextProvider = ({ children }) => {
  const { endedUserLogic, Logout } = useContext(AuthContext);
  const { endedBrowserLogic } = useContext(BrowserContext);
  const { theme } = useContext(ThemeContext);
  const { translate } = useContext(LanguageContext);

  const [loadInterface, setLoadInterface] = useState(true);
  const [loadingGateway, setLoadingGateway] = useState(false);
  const [message, setMessage] = useState(translate.messages.loading.text);
  const [showApology, setShowApology] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  useEffect(() => {
    const piece1 = query.get(UrlParameters.gatewayPiece1);
    if (piece1) return setLoadingGateway(true);
  }, [query]);

  useEffect(() => {
    if (endedBrowserLogic && endedUserLogic) {
      if (isDebug) console.log('Logics finished');
      setLoadInterface(false);
    }
  }, [endedBrowserLogic, endedUserLogic]);

  useEffect(() => {
    if (showApology) {
      if (!endedBrowserLogic || !endedUserLogic) {
        if (isDebug) console.log('Logout');
        Logout(window.location.href);
        document.location.reload(true);
      }
    }
  }, [showApology, Logout, endedBrowserLogic, endedUserLogic]);

  useEffect(() => {
    async function Slow() {
      if (loadInterface) {
        setMessage(translate.messages.almostThere.text);
      }
    }
    async function VerySlow() {
      if (loadInterface) {
        setMessage(translate.messages.itWasntSupposedToTakeThatLong.text);
      }
    }
    async function VeryVerySlow() {
      if (loadInterface) {
        setShowApology(true);
      }
    }
    setTimeout(function () {
      Slow();
    }, 3000);
    setTimeout(function () {
      VerySlow();
    }, 10000);
    setTimeout(function () {
      VeryVerySlow();
    }, 20000);
  }, [loadInterface, translate]);

  useEffect(() => {
    if (!loadInterface) {
      CookieNotification({
        message: translate.messages.cookieNotification.text,
        buttonText: translate.basic.accepted.text,
        policyButtonText: translate.basic.privacyPolicy.text,
      });
    }
  }, [loadInterface, translate]);

  const ToggleLoadInterface = () => {
    setLoadInterface(!loadInterface);
  };

  return (
    <InterfaceContext.Provider value={{ loadInterface, ToggleLoadInterface }}>
      {loadInterface ? (
        <Container
          style={{
            backgroundColor:
              (theme && theme.color && theme.color.backgroundSecondary) ||
              '#fff',
            color: (theme && theme.color && theme.color.primary) || '#fff',
          }}
        >
          {showApology ? (
            <ApologyContainer>
              <Title level={4}>
                {translate.messages.sorryForTheDelay.text}
              </Title>
              <Text>{translate.messages.tryAgainLater.text}</Text>
            </ApologyContainer>
          ) : loadingGateway ? (
            <LoadingGateway />
          ) : (
            <Loading text={message} />
          )}
        </Container>
      ) : (
        children
      )}
    </InterfaceContext.Provider>
  );
};

export default InterfaceContextProvider;
