// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/Comfortaa.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@font-face {\n    font-family: Comfortaa;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")\n  }\n /* \n  * {\n    font-family: Comfortaa;\n  }\n  */\n.ex{\n    transition-delay: 0s;\n    transition: all 0.5s;\n    -webkit-transition: all 0.5s;\n}\n.ant-form-item-explain{\n    margin: 0px !important;\n}\n/* INPUTS */\ninput[type='password'] {\n    color: inherit !important;\n}\ninput[type='text'] {\n    color: inherit !important;\n}\n.ant-input-password-icon {\n    color: inherit !important;\n}\n.ant-input[disabled] {\n    color: inherit !important;\n}\n/* SELECTS */\n.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {\n    background-color: inherit !important;\n}\n.ant-select-arrow {\n    color: inherit !important;\n}\n.ant-select-selector{\n    border-radius: 8px !important;\n    text-align: left !important;\n}\n/* MODAL */\n.ant-modal-content{\n    border-radius:8px !important;\n}\n.ant-modal-header{\n    border-radius: 8px 8px 0 0 !important;\n}\n.ant-modal-footer button {\n    border-radius:8px !important;\n}\n/* BUTTON MODAL */\n.ant-modal-confirm-btns .ant-btn{\n    border-radius: 8px !important;\n}\n/* Tooltip */\n.ant-tooltip{\n    background-color: rgba(0, 0, 0, 0) !important;\n}\n.ant-tooltip-inner{\n    background-color: rgba(0, 0, 0, 1) !important;\n}\n/* SIDEBAR */\n.ant-layout-sider-children{\n    width: 100% !important;\n}\n\n/* FORM */\n.ant-form-item{\n    line-height: 3 !important;\n}\n\n/* INPUT */\n.ant-form-item-explain{\n    display: flex !important;\n    text-align: justify !important;\n}\n\n/* INPUT NUMBER */\n.ant-input-number-input{\n    text-align: end !important;\n    padding-right: 30px !important;\n}\n\n/* Notifications */\n.message-context-notification > .ant-notification-notice-close{\n    display: none;\n}\n.message-context-notification .ant-notification-notice-message{\n    display: none;\n}\n.ant-notification-notice{\n    border-radius: 8px !important;\n}", ""]);
// Exports
module.exports = exports;
