import Auth from './auth';
import Donation from './donation';
import Person from './person';
import Product from './product';
import Profile from './profile';
import System from './system';

const Request = {
  Auth,
  Donation,
  Person,
  Product,
  Profile,
  System,
};

export default Request;
