import React from 'react';
//Libraries
import { notification } from 'antd';
// Components
import CookiesNotification from '~/components/notification/cookiesNotification';
// Environments
import System from '~/environments/System';
// Utils
import Cookie from '~/utils/Cookie';

export function CookieNotification({ message, buttonText, policyButtonText }) {
  const OpenCookieNotification = () => {
    const close = () => {
      Cookie.Create({
        name: System.cookie.name.acceptedCookies,
        value: true,
      });
      notification.close(key);
    };
    const key = `open${Date.now()}`;
    const content = (
      <CookiesNotification
        message={message}
        buttonText={buttonText}
        policyButtonText={policyButtonText}
        onClick={close}
      />
    );
    const placement = 'topLeft';
    notification.open({
      description: content,
      className: 'message-context-notification',
      duration: null,
      placement,
      key,
      onClose: close,
    });
  };
  async function VerifyAcceptCookies() {
    const AcceptCookies = await Cookie.Get(System.cookie.name.acceptedCookies);
    const UserTokenCookies = await Cookie.Get(System.cookie.name.user);
    if (!UserTokenCookies && !AcceptCookies) {
      return OpenCookieNotification();
    }
    return;
  }
  VerifyAcceptCookies();
}
