import React from 'react';
import Text from '~/components/typography/text';
//Utils
import { GetBrandIcon } from '~/utils/SocialMeAssets';

export default function ModuleCard({ brandName, text, url }) {
  return (
    <div style={{ padding: 10 }}>
      <a href={url}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#fff',
            borderRadius: 25,
            padding: 5,
          }}
        >
          <div>
            <img
              src={GetBrandIcon(brandName)}
              alt="..."
              style={{ height: 32, marginRight: 5 }}
            />
          </div>
          <div>
            <Text strong>{text}</Text>
          </div>
        </div>
      </a>
    </div>
  );
}
