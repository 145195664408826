export default {
  SM04C8874: {
    message: 'User account successfully listed',
  },
  SMF5177B8: {
    message: 'The account balance was successfully Read',
  },
  SMD275823: {
    message: 'The digital wallet account was successfully Read',
  },
  SM7533DBE: {
    message: 'The qr-code was successfully Listed',
  },
  SMB2D88E7: {
    message: "The 'detailed account balance' was successfully Read",
  },
  SME9664D8: {
    message: 'The qr-code was successfully Read',
  },
  SMC7BCEEA: {
    message: 'The product/service model was successfully Listed',
  },
  SM8117F92: {
    message: 'The measurement unity was successfully Listed',
  },
};
