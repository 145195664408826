const Options = [
  //conta
  {
    value: 'Fazer login',
    url: 'https://conta.socialme.com.br',
  },
  {
    value: 'Entrar',
    url: 'https://conta.socialme.com.br',
  },
  {
    value: 'Acessar o Social Me',
    url: 'https://conta.socialme.com.br',
  },
  {
    value: 'Cadastrar',
    url: 'https://conta.socialme.com.br',
  },
  {
    value: 'Fazer cadastro',
    url: 'https://conta.socialme.com.br',
  },
  //My Account
  {
    value: 'Acessar minha conta',
    url: 'https://perfil.socialme.com.br',
  },
  {
    value: 'Gerenciar minha conta',
    url: 'https://perfil.socialme.com.br',
  },
  {
    value: 'Alterar meu nome',
    url: 'https://perfil.socialme.com.br/informacoes-basicas',
  },
  {
    value: 'Alterar minha idade',
    url: 'https://perfil.socialme.com.br/informacoes-basicas',
  },
  {
    value: 'Alterar minha data de nascimento',
    url: 'https://perfil.socialme.com.br/informacoes-basicas',
  },
  {
    value: 'Alterar minha foto de perfil',
    url: 'https://perfil.socialme.com.br/informacoes-basicas',
  },
  {
    value: 'Alterar meu email',
    url: 'https://perfil.socialme.com.br/informacoes-basicas',
  },
  {
    value: 'Alterar meu sexo',
    url: 'https://perfil.socialme.com.br/informacoes-basicas',
  },
  {
    value: 'Alterar meu estado civil',
    url: 'https://perfil.socialme.com.br/informacoes-basicas',
  },
  {
    value: 'Alterar minha escolaridade',
    url: 'https://perfil.socialme.com.br/informacoes-basicas',
  },
  {
    value: 'Alterar minha cidade de nascimento',
    url: 'https://perfil.socialme.com.br/informacoes-basicas',
  },
  {
    value: 'Alterar minha estado de nascimento',
    url: 'https://perfil.socialme.com.br/informacoes-basicas',
  },
  {
    value: 'Alterar meu país de nascimento',
    url: 'https://perfil.socialme.com.br/informacoes-basicas',
  },
  {
    value: 'Alterar meu nome social',
    url: 'https://perfil.socialme.com.br/informacoes-basicas',
  },
  {
    value: 'Alterar meu sexo social',
    url: 'https://perfil.socialme.com.br/informacoes-basicas',
  },
  {
    value: 'Alterar meu gênero',
    url: 'https://perfil.socialme.com.br/informacoes-basicas',
  },
  {
    value: 'Consultar meu QRCode',
    url: 'https://perfil.socialme.com.br/informacoes-basicas',
  },
  {
    value: 'Meus documentos',
    url: 'https://perfil.socialme.com.br/documentos',
  },
  {
    value: 'Gerenciar meus documentos',
    url: 'https://perfil.socialme.com.br/documentos',
  },
  {
    value: 'Gerenciar meu CPF',
    url: 'https://perfil.socialme.com.br/documentos',
  },
  {
    value: 'Gerenciar meu RG',
    url: 'https://perfil.socialme.com.br/documentos',
  },
  {
    value: 'Gerenciar minha CNS',
    url: 'https://perfil.socialme.com.br/documentos',
  },
  {
    value: 'Gerenciar minha CNH',
    url: 'https://perfil.socialme.com.br/documentos',
  },
  {
    value: 'Gerenciar minha Carteira de Trabalho',
    url: 'https://perfil.socialme.com.br/documentos',
  },
  {
    value: 'Gerenciar minha Certidão de Nascimento',
    url: 'https://perfil.socialme.com.br/documentos',
  },
  {
    value: 'Gerenciar meu Título de Eleitor',
    url: 'https://perfil.socialme.com.br/documentos',
  },
  {
    value: 'Gerenciar meu Passaporte',
    url: 'https://perfil.socialme.com.br/documentos',
  },
  {
    value: 'Gerenciar meus telefones',
    url: 'https://perfil.socialme.com.br/contatos',
  },
  {
    value: 'Adicionar número de telefone',
    url: 'https://perfil.socialme.com.br/contatos',
  },
  {
    value: 'Adicionar telefone',
    url: 'https://perfil.socialme.com.br/contatos',
  },
  {
    value: 'Gerenciar meus endereços',
    url: 'https://perfil.socialme.com.br/contatos',
  },
  {
    value: 'Adicionar novo endereço',
    url: 'https://perfil.socialme.com.br/contatos',
  },
  {
    value: 'Adicionar endereço',
    url: 'https://perfil.socialme.com.br/contatos',
  },
  {
    value: 'Ver minha linha do tempo',
    url: 'https://perfil.socialme.com.br/linha-do-tempo',
  },
  {
    value: 'Ver meus relacionamentos',
    url: 'https://perfil.socialme.com.br/relacionamentos',
  },
  {
    value: 'Adicionar um novo relacionamento',
    url: 'https://perfil.socialme.com.br/relacionamentos',
  },
  {
    value: 'Ver solicitações de relacionamentos recebidas',
    url:
      'https://perfil.socialme.com.br/relacionamentos/solicitacoes-recebidas',
  },
  {
    value: 'Ver solicitações de relacionamentos realizadas',
    url:
      'https://perfil.socialme.com.br/relacionamentos/solicitacoes-realizadas',
  },
  {
    value: 'Ver questionário sobre Família',
    url: 'https://perfil.socialme.com.br/familia',
  },
  {
    value: 'Ver questionário sobre Debilitações e Deficiências',
    url: 'https://perfil.socialme.com.br/debilitacoes-e-deficiencias',
  },
  {
    value: 'Ver questionário sobre Trabalho e Remuneração',
    url: 'https://perfil.socialme.com.br/trabalho-e-remuneracao',
  },
  {
    value: 'Ver questionário sobre Vinculações a Programas e Serviços',
    url: 'https://perfil.socialme.com.br/vinculacao-a-programas-e-servicos',
  },
  {
    value: 'Ver questionário sobre Situação de Rua',
    url: 'https://perfil.socialme.com.br/situacao-de-rua',
  },
  //carteira
  {
    value: 'Ver minha carteira',
    url: 'https://carteira.socialme.com.br',
  },
  {
    value: 'Consultar meu saldo',
    url: 'https://carteira.socialme.com.br',
  },
  {
    value: 'Ver extrato da minha carteira',
    url: 'https://carteira.socialme.com.br',
  },
  {
    value: 'Solicitar meu cartão Social Me',
    url: 'https://carteira.socialme.com.br',
  },
  {
    value: 'Ativar meu cartão Social Me',
    url: 'https://carteira.socialme.com.br',
  },
  {
    value: 'Bloquear meu cartão Social Me',
    url: 'https://carteira.socialme.com.br',
  },
  {
    value: 'Saldo da conta corrente',
    url: 'https://carteira.socialme.com.br',
  },
  {
    value: 'Extrato da conta corrente',
    url: 'https://carteira.socialme.com.br',
  },
  {
    value: 'Solicitar saque',
    url: 'https://carteira.socialme.com.br',
  },
  {
    value: 'Transferência',
    url: 'https://carteira.socialme.com.br',
  },
  {
    value: 'Pagar',
    url: 'https://carteira.socialme.com.br',
  },
  {
    value: 'Pagamentos',
    url: 'https://carteira.socialme.com.br',
  },
  {
    value: 'Recarga',
    url: 'https://carteira.socialme.com.br',
  },
  {
    value: 'Recarga de saldo para o meu chip Social Me',
    url: 'https://carteira.socialme.com.br',
  },
  {
    value: 'Adicionar saldo na minha conta Social Me',
    url: 'https://carteira.socialme.com.br',
  },
  //Beneficiario
  {
    value: 'Consultar meu painel do Beneficiário',
    url: 'https://beneficiario.socialme.com.br/inicio',
  },
  {
    value: 'Painel do Beneficiário',
    url: 'https://beneficiario.socialme.com.br/inicio',
  },
  {
    value: 'Minhas atividades',
    url: 'https://beneficiario.socialme.com.br/atividades',
  },
  {
    value: 'Registrar nova atividade',
    url: 'https://beneficiario.socialme.com.br/atividades/nova-atividade',
  },
  {
    value: 'Registrar frequência',
    url: 'https://beneficiario.socialme.com.br/atividades/nova-atividade',
  },
  {
    value: 'Ver meus pedidos de doação',
    url: 'https://beneficiario.socialme.com.br/doacoes',
  },
  {
    value: 'Meus pedidos de doação',
    url: 'https://beneficiario.socialme.com.br/doacoes',
  },
  {
    value: 'Solicitar um pedido de doação',
    url: 'https://beneficiario.socialme.com.br/doacoes/solicitar-doacao',
  },
  {
    value: 'Pedir doação',
    url: 'https://beneficiario.socialme.com.br/doacoes/solicitar-doacao',
  },
  {
    value: 'Meu registro como beneficiária',
    url: 'https://beneficiario.socialme.com.br/movimentacoes',
  },
  {
    value: 'Meu histórico de beneficiária',
    url: 'https://beneficiario.socialme.com.br/movimentacoes',
  },
  {
    value: 'Meus programas onde estou cadastrada',
    url: 'https://beneficiario.socialme.com.br/programas',
  },
  {
    value: 'Oportunidades',
    url: 'https://beneficiario.socialme.com.br/oportunidades',
  },
  {
    value: 'Programas disponíveis para entrar',
    url:
      'https://beneficiario.socialme.com.br/oportunidades/programas-disponiveis',
  },
  {
    value: 'Programas que me convidaram',
    url:
      'https://beneficiario.socialme.com.br/oportunidades/convites-recebidos',
  },
  {
    value: 'Convites para a entrada em programas',
    url:
      'https://beneficiario.socialme.com.br/oportunidades/solicitacoes-realizadas',
  },
  {
    value: 'Meus benefícios',
    url:
      'https://beneficiario.socialme.com.br/oportunidades/beneficios-para-resgate',
  },
  {
    value: 'Pegar benefício',
    url:
      'https://beneficiario.socialme.com.br/oportunidades/beneficios-para-resgate',
  },
  {
    value: 'Benefícios disponíveis',
    url:
      'https://beneficiario.socialme.com.br/oportunidades/beneficios-para-resgate',
  },
  //Manage
  {
    value: 'Painel do Gestor',
    url: 'https://gestor.socialme.com.br',
  },
  {
    value: 'Consultar painel do Gestor',
    url: 'https://gestor.socialme.com.br',
  },
  {
    value: 'Meus tipos de atividades como Gestor',
    url: 'https://gestor.socialme.com.br/atividades',
  },
  {
    value: 'Adicionar novo tipo de atividade como Gestor',
    url: 'https://gestor.socialme.com.br/atividades/nova-atividade',
  },
  {
    value: 'Meus benefícios cadastrados',
    url: 'https://gestor.socialme.com.br/beneficios',
  },
  {
    value: 'Meus produtos cadastrados',
    url: 'https://gestor.socialme.com.br/beneficios/produtos',
  },
  {
    value: 'Meus serviços cadastrados',
    url: 'https://gestor.socialme.com.br/beneficios/servicos',
  },
  {
    value: 'Minhas movimentações como gestor',
    url: 'https://gestor.socialme.com.br/movimentacoes',
  },
  {
    value: 'Meu histórico como gestor',
    url: 'https://gestor.socialme.com.br/movimentacoes',
  },
  {
    value: 'Programas que administro',
    url: 'https://gestor.socialme.com.br/programas',
  },
  {
    value: 'Adicionar novo programa',
    url: 'https://gestor.socialme.com.br/programas/novo-programa',
  },
  //Parceiro
  {
    value: 'Meu painel do Fornecedor',
    url: 'https://parceiro.socialme.com.br',
  },
  {
    value: 'Minhas atividades como fornecedor',
    url: 'https://parceiro.socialme.com.br/atividades',
  },
  {
    value: 'Registrar nova atividade',
    url: 'https://parceiro.socialme.com.br/atividades/nova-atividade',
  },
  {
    value: 'Registrar frequência',
    url: 'https://parceiro.socialme.com.br/atividades/nova-atividade',
  },
  {
    value: 'Minhas movimentações como fornecedor',
    url: 'https://parceiro.socialme.com.br/movimentacoes',
  },
  {
    value: 'Meu histórico como fornecedor',
    url: 'https://parceiro.socialme.com.br/movimentacoes',
  },
  {
    value: 'Meu negócio',
    url: 'https://parceiro.socialme.com.br/meu-negocio',
  },
  {
    value: 'Cadastrar um novo negócio',
    url: 'https://parceiro.socialme.com.br/meu-negocio',
  },
  {
    value: 'Ver os produtos da minha loja',
    url: 'https://parceiro.socialme.com.br/meu-negocio/produtos',
  },
  {
    value: 'Cadastrar produtos na minha loja',
    url: 'https://parceiro.socialme.com.br/meu-negocio/produtos',
  },
  {
    value: 'Ver os serviços da minha loja',
    url: 'https://parceiro.socialme.com.br/meu-negocio/servicos',
  },
  {
    value: 'Cadastrar serviços na minha loja',
    url: 'https://parceiro.socialme.com.br/meu-negocio/servicos',
  },
  //doador
  {
    value: 'Quero ser um Doador',
    url: 'https://doador.socialme.com.br',
  },
  {
    value: 'Quero fazer uma doação',
    url: 'https://doador.socialme.com.br',
  },
  {
    value: 'Meu painel de doador',
    url: 'https://doador.socialme.com.br',
  },
  {
    value: 'Minhas movimentações como doador',
    url: 'https://doador.socialme.com.br/movimentacoes',
  },
  {
    value: 'Meu histórico como doador',
    url: 'https://doador.socialme.com.br/movimentacoes',
  },
  //Doações.me
  {
    value: 'Doar',
    url: 'https://doacoes.me',
  },
  {
    value: 'Ver doações',
    url: 'https://doacoes.me',
  },
];

export default Options;
