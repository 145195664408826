import React, { useState, useEffect, useContext } from 'react';
//Libraries
import { AutoComplete, Row, Col } from 'antd';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
//Components
import Navbar from '~/components/navbar/Main';
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
//Assets
import bg from '~/assets/images/backgrounds/bg2.jpg';

import ModuleCard from './components/ModuleCard';

import options from './options';

const { Option } = AutoComplete;

export default function Achei() {
  const { isMobile } = useContext(ResponsiveContext);
  const [optionsSort, setOptionsSort] = useState([]);

  useEffect(() => {
    let newOptions = options.sort((a, b) => (a.value > b.value ? 1 : -1));
    setOptionsSort(newOptions);
  }, []);

  const onSelect = (value) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === value) {
        //return console.log(options[i].url);
        return (window.location.href = options[i].url);
      }
    }
  };
  return (
    <div>
      <Navbar withoutSidebar />
      <div
        style={
          isMobile
            ? {
                height: 'calc(100vh - 42px)',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundImage: `url('${bg}')`,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'column',
                padding: 10,
                marginTop: 10,
              }
            : {
                height: 'calc(100vh - 42px)',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundImage: `url('${bg}')`,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'column',
                padding: 50,
              }
        }
      >
        <div>
          <Title
            style={{ color: '#fff', textAlign: 'center' }}
            level={isMobile ? 4 : 1}
          >
            O que você está procurando?
          </Title>
          <AutoComplete
            size="large"
            style={{
              width: isMobile ? '100%' : 600,
              maxWidth: '600px',
              backgroundColor: '#fff',
              marginTop: 20,
              borderRadius: 25,
            }}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
            onSelect={onSelect}
          >
            {optionsSort.map((item) => (
              <Option key={item.value} value={item.value}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <Text strong>{item.value}</Text>
                </div>
              </Option>
            ))}
          </AutoComplete>
        </div>

        <div style={{ width: '100%', marginTop: 50 }}>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <ModuleCard
                brandName="accountManagement"
                text="Minha conta"
                url="https://perfil.socialme.com.br/"
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <ModuleCard
                brandName="beneficiary"
                text="Beneficiário"
                url="https://beneficiario.socialme.com.br/"
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <ModuleCard
                brandName="manager"
                text="Gestor"
                url="https://gestor.socialme.com.br/"
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <ModuleCard
                brandName="partner"
                text="Parceiro"
                url="https://parceiro.socialme.com.br/"
              />
            </Col>
            {isMobile ? <></> : <Col span={2}></Col>}
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <ModuleCard
                brandName="donor"
                text="Doador"
                url="https://doador.socialme.com.br/"
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <ModuleCard
                brandName="digitalWallet"
                text="Carteira"
                url="https://carteira.socialme.com.br/"
              />
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
              <ModuleCard
                brandName="donationsPortal"
                text="Doações Me"
                url="https://doacoes.me/"
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
